import { useState } from 'react';

import { type ActionFunctionArgs, json, type LoaderFunctionArgs, redirect } from '@remix-run/node';
import { useSubmit } from '@remix-run/react';

import { Check, Event, Map } from '@mui/icons-material';

import { Overlay } from '@shared/styled';
import { withStyledComponents } from '@shared/with-styled-component';

import styles from '~/routes-styles/index.module.css';
import { userPrefs } from '~/user-preferences.server';
import { event as GAEvent } from '~/utils/gtags.client';

import Logo from '@images/logo-main.png';

const Wrapper = withStyledComponents(styles.SplashWrapper, 'div');
const InnerWrapper = withStyledComponents(styles.InnerWrapper, 'div');
const InnerInnerWrapper = withStyledComponents(styles.InnerInnerWrapper, 'div');
const Divider = withStyledComponents(styles.Divider, 'div');
const LinkBtn = withStyledComponents(styles.Button, 'button');
const PhotoCredit = withStyledComponents(styles.PhotoCredit, 'div');
const CheckBoxButton = withStyledComponents(styles.CheckBoxButton, 'button');
const CheckboxVisual = withStyledComponents(styles.CheckboxVisual, 'span', { hasBackground: styles.hasBackground });
const ButtonWrapper = withStyledComponents(styles.ButtonWrapper, 'div');

export const meta = () => {
  return [
    { title: 'Dodgeball Hub' },
    { name: 'description', content: 'Welcome to Dodgeball Hub! Find dodgeball events near you!' },
    { property: 'og:image', content: 'https://www.dodgeballhub.com/og-img.png?version=1' },
    { property: 'og:title', content: 'Dodgeball Hub' },
    { property: 'og:description', content: 'Welcome to Dodgeball Hub! Find dodgeball events near you!' },
    { property: 'og:url', content: 'https://www.dodgeballhub.com' },
  ];
};
export async function loader({ request }: LoaderFunctionArgs) {
  const cookieHeader = request.headers.get('Cookie');
  const cookie = (await userPrefs.parse(cookieHeader)) || {};

  if (cookie.choice) {
    return redirect(`/events/${cookie.choice}`);
  }
  return json({});
}

export async function action({ request }: ActionFunctionArgs) {
  const cookieHeader = request.headers.get('Cookie');
  const cookie = (await userPrefs.parse(cookieHeader)) || {};
  const bodyParams = await request.formData();

  if (bodyParams.get('saveChoice') === 'true') {
    cookie.choice = bodyParams.get('choice');
  }

  return redirect(`/events/${bodyParams.get('choice')}`, {
    headers: {
      'Set-Cookie': await userPrefs.serialize(cookie),
    },
  });
}

export default function Index() {
  const submit = useSubmit();
  const [checked, setChecked] = useState(false);

  const handleClick = (choice: 'calendar' | 'map') => {
    const formData = new FormData();
    formData.set('choice', choice);
    formData.set('saveChoice', `${checked}`);

    GAEvent({ action: 'splash_choose', value: JSON.stringify({ view: choice }) });
    submit(formData, { method: 'post' });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <img src={Logo} alt="DodgeballHub" width={180} />
        <InnerInnerWrapper>
          <h1>Find dodgeball events near you!</h1>
          <ButtonWrapper>
            <LinkBtn type="button" onClick={() => handleClick('calendar')}>
              <Event />
              View the calendar
            </LinkBtn>
            <Divider />
            <LinkBtn type="button" onClick={() => handleClick('map')}>
              <Map />
              View the map
            </LinkBtn>
          </ButtonWrapper>
        </InnerInnerWrapper>

        <CheckBoxButton
          onClick={() => {
            const newVal = !checked;

            setChecked(newVal);
            GAEvent({ action: 'splash_remember_choice', value: JSON.stringify({ checked: newVal }) });
          }}
        >
          <CheckboxVisual hasBackground={checked}>{checked && <Check />}</CheckboxVisual>
          Remember my choice
        </CheckBoxButton>
      </InnerWrapper>
      <Overlay style={{ zIndex: 0, opacity: '0.5' }} />
      <PhotoCredit>
        Photo by{' '}
        <a href="https://unsplash.com/fr/@dead____artist?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Z
        </a>{' '}
        on{' '}
        <a href="https://unsplash.com/photos/TrhLCn1abMU?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Unsplash
        </a>
      </PhotoCredit>
    </Wrapper>
  );
}
